<template>
  <default-layout :breadcrumbs="breadcrumbs" :useFooter="false">
    <h1 style="display: none;">{{ $t('general.searchPage') }}</h1>
    <div class="container search--container-wrapper">
      <maps :listings="listings" :meta="meta" :mapTypeControl="true" :isLoading="isLoading" />
      <div class="search--container" :class="{ 'closed--map': !showMap }">
        <search-header></search-header>
        <div class="d-flex justify-content-between">
          <div class="left--col">
            <search-content></search-content>
          </div>
          <div class="right--col" :class="{ active: isActive }" v-if="$mq === 'xs'">
            <search-filters></search-filters>
          </div>
        </div>
      </div>
    </div>
    <popup-compare></popup-compare>
    <modal-compare></modal-compare>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import SearchHeader from '@/components/search/header';
import SearchContent from '@/components/search/content';
import SearchFilters from '@/components/search/filters';
const PopupCompare = () => import('@/components/search/compare-popup');
const ModalCompare = () => import('@/components/search/modals/compare-modal');
const Maps = () => import('@/components/search/partials/map');

import DefaultHeadMixin from '@/mixins/defaultHead';
import HelperMixin from '@/mixins/helpers';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'search-page',
  mixins: [DefaultHeadMixin, HelperMixin],
  components: {
    DefaultLayout,
    SearchHeader,
    SearchContent,
    SearchFilters,
    PopupCompare,
    ModalCompare,
    Maps,
  },
  computed: {
    ...mapState({
      isActive: state => state.v2.search.activeFilterSidebar,
      listingType: state => state.v2.search.listingType,
      listings: state => state.v2.search.listings,
      isLoading: state => state.global.isLoading,
      showMap: state => state.global.showMap,
    }),
    ...mapGetters({
      propertyTypeString: 'v2/masters/selectedPropertyTypesString',
    }),
    meta() {
      let searchType = this.$store.state.v2.search.searchType;
      if (searchType === 2) {
        return this.$store.state.v2.search.meta;
      } else if (searchType === 1) {
        return this.$store.state.v2.search.metaProject;
      }
      return null;
    },
    typeString() {
      return this.listingType === 'R' ? this.$t('general.rent') : this.$t('general.sell');
    },
    ownerName() {
      const owner = this.$store.state.v2.profile.owner;
      return owner ? owner.fullname : '';
    },
    breadcrumbs() {
      if (this.$route.name === 'search') {
        return [
          {
            text: this.$t('breadcrumb.homepage'),
            to: '/',
          },
          {
            text: `${this.typeString} ${this.propertyTypeString}`,
            to: this.$router.currentRoute.path,
          },
        ];
      } else if (this.$route.name === 'search-user-listing') {
        return [
          {
            text: this.$t('breadcrumb.homepage'),
            to: '/',
          },
          {
            text: this.ownerName,
            to: this.$router.currentRoute.path,
          },
        ];
      }
      return [];
    },
    jsonldBreadcrumb() {
      let baseUrl = this.$store.state.global.baseUrl;
      let text = `${this.typeString} ${this.propertyTypeString}`;
      if (this.$route.name === 'search-user-listing') {
        text = this.ownerName;
      }
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: text,
            item: baseUrl + this.$router.currentRoute.path,
          },
        ],
      };
    },
    jsonld() {
      let baseUrl = this.$store.state.global.baseUrl;
      let path = this.$router.currentRoute.path;
      const listingType2 = this.listingType === 'R' ? 'for Rent & Sale' : 'for Rent & Sale';
      const listingType3 = this.listingType === 'R' ? 'Rent & Buy' : 'Rent & Buy';
      const searchTerm = this.$store.state.v2.search.searchTerm;
      const propertyType = this.$store.getters['v2/masters/selectedPropertyTypesString'];
      const location = searchTerm ? searchTerm : 'Singapore';

      let title = propertyType + ' ' + listingType2 + ' in ' + location;
      let description =
        listingType3 +
        ' ' +
        propertyType +
        ' in ' +
        location +
        ' with Propfix at affordable prices. Propfix is a website that helps you search ' +
        propertyType +
        ' in ' +
        location +
        ' for you.';

      let itemList = [],
        i = 1;
      for (let listing of this.listings) {
        listing.isActive = true;
        itemList.push({
          '@type': 'ListItem',
          position: i,
          item: this.getListingJsonld({
            baseUrl: baseUrl,
            path: listing.links.detail,
            listing: listing,
            galleries: listing.photos,
          }),
        });
        i++;
      }

      return {
        '@type': 'RealEstateListing',
        description: description,
        name: title,
        url: baseUrl + path,
        mainEntity: {
          '@type': 'ItemList',
          itemListElement: itemList,
        },
      };
    },
  },
  async fetch({ store, params, route, query, error }) {
    console.log(
      'Panggil get search listing dari fetch di search/index.vue dengan isi route: ',
      route,
    );

    store.commit('global/SET_LOADING', true);

    await store.dispatch('v2/search/loadListingTypeFromRoute', {
      query: query,
      params: route.params,
    });
    await store.dispatch('v2/search/loadSearchTypeFromRoute', {
      query: query,
      params: route.params,
    });
    console.log('SEARCH TYPE: ', store.state.v2.search.searchType);

    store.commit('v2/search/set_listings', { data: [], meta: null });
    store.commit('v2/search/set_premier_listings', []);
    store.commit('v2/search/set_projects', { data: [], meta: null });

    let allPromises = [];
    const categoryId = parseInt(query.property_category);
    await store.dispatch('v2/search/setCategory', {
      payload: categoryId,
      query: query,
    });

    await store.dispatch('v2/search/loadListingTypeFromRoute', {
      query: query,
      params: route.params,
    });
    if (store.state.v2.search.listingType === 'R') {
      allPromises.push(store.dispatch('v2/masters/getPropertyTypeCategories', categoryId));
      allPromises.push(store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
      allPromises.push(store.dispatch('v2/masters/getRentTypesByCategory', categoryId));
      allPromises.push(store.dispatch('v2/masters/getRentPeriodsByCategory', categoryId));
      allPromises.push(store.dispatch('v2/masters/getTenureTypes'));
      allPromises.push(
        store.dispatch('v2/masters/getDistricts', {
          listingType: store.state.v2.search.listingType,
        }),
      );
    } else {
      allPromises.push(store.dispatch('v2/masters/getPropertyTypeCategories', categoryId));
      allPromises.push(store.dispatch('v2/masters/getPropertyTypesByCategory', categoryId));
      allPromises.push(
        store.dispatch('v2/masters/getDistricts', {
          listingType: store.state.v2.search.listingType,
        }),
      );
    }
    try {
      await Promise.all(allPromises);
    } catch (e) {
      error(new Error(e.message), 404);
    }

    let newParams = route.params;
    let owner = null;
    if (params.userHashId) {
      owner = await store.dispatch('v2/profile/getOwner', params.userHashId);
      if (owner.preferred_listing_type) {
        store.commit('v2/search/SET_LISTING_TYPE', owner.preferred_listing_type);
      }
      if (owner && owner.uuid) {
        params.userUuid = owner.uuid;
      }
      store.commit('global/SET_SHOW_MAP', true);
    }

    allPromises = [];
    if (store.state.v2.search.searchType === 2) {
      allPromises.push(
        store.dispatch('v2/search/getSearchListing', {
          query: query,
          params: newParams,
        }),
      );
      // allPromises.push(store.dispatch('v2/search/getGeoJsonListings', query));
    } else {
      allPromises.push(
        store.dispatch('v2/search/getProjectPartners', {
          query: query,
          params: newParams,
        }),
      );
    }

    try {
      await Promise.all(allPromises);
    } catch (e) {
      error(new Error(e.message), 404);
    }
    store.commit('global/SET_LOADING', false);

    // await store.dispatch('v2/masters/getPropertyTypes', query.property_group?Number(query.property_group):1);
    let activePropertyTypes = store.state.v2.search.activePropertyTypes;
    if (activePropertyTypes && activePropertyTypes.length > 0) {
      store.commit(
        'v2/masters/SET_SELECTED_PROPERTY_TYPES',
        activePropertyTypes.map(propertyType => {
          return propertyType.name ? propertyType.name : 'Property';
        }),
      );
    } else {
      store.commit('v2/masters/SET_SELECTED_PROPERTY_TYPES', []);
    }

    if (route.name === 'search') {
      const listingType = await store.dispatch(
        'v2/search/convertListingTypeStringToId',
        route.params.listing_type,
      );
      const listingType2 = listingType === 'R' ? 'for Rent & Sale' : 'for Rent & Sale';
      const listingType3 = listingType === 'R' ? 'Rent & Buy' : 'Rent & Buy';
      const searchTerm = store.state.v2.search.searchTerm;
      const propertyType = store.getters['v2/masters/selectedPropertyTypesString'];
      const location = searchTerm ? searchTerm : 'Singapore';

      const baseUrl = store.state.global.baseUrl;
      let title = propertyType + ' ' + listingType2 + ' in ' + location;
      // if (title.length > 52) title = title.substring(0, 49) + '...';
      title += ' | Propfix';

      let description =
        listingType3 +
        ' ' +
        propertyType +
        ' in ' +
        location +
        ' with Propfix at affordable prices. Propfix is a website that helps you search ' +
        propertyType +
        ' in ' +
        location +
        ' for you.';
      if (description.length > 307) description = description.substring(0, 304) + '...';
      description += ' | Propfix';

      const head = {
        title: title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
          {
            hid: 'og-description',
            property: 'og:description',
            content: description,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    } else if (route.name === 'search-user-listing') {
      const name = owner ? owner.fullname : '';
      const baseUrl = store.state.global.baseUrl;
      const profilePictureUrl = owner ? owner.user_photo : '';
      const title = 'Property Listings by ' + name + ' | Propfix';
      const description =
        'Propfix is working with ' + name + ' to make full occupancy happen. | Propfix';
      const head = {
        title: title,
        meta: [
          {
            hid: 'og-url',
            property: 'og:url',
            content: baseUrl + route.fullPath,
          },
          { hid: 'og-type', property: 'og:type', content: 'article' },
          {
            hid: 'og-title',
            property: 'og:title',
            content: title,
          },
          {
            hid: 'og-description',
            property: 'og:description',
            content: description,
          },
          { hid: 'og-site-name', property: 'og:site_name', content: 'Propfix SG' },
          {
            hid: 'og-image',
            property: 'og:image',
            content: profilePictureUrl,
          },
          { hid: 'og-image-width', property: 'og:image:width', content: '450' },
          { hid: 'og-image-height', property: 'og:image:height', content: '450' },
          {
            hid: 'description',
            name: 'description',
            content: description,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    } else {
      store.commit('head/RESTORE_DEFAULT_VALUE', route);
    }
  },
  async mounted() {
    this.mountSearch();
  },
  methods: {
    async mountSearch() {
      if (this.$store.state.global.loggedIn) {
        this.$store.dispatch('v2/search/getShortlists');
      }
    },
  },
};
</script>
